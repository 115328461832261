.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* #blogHeader1 {
  margin-left: 7%;
  border-left: 5px solid #000;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.masthead {
  /* content:""; */
  /* position: absolute; */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  height: 85vh;
  /* background: linear-gradient(
    to bottom,
    rgb(224, 74, 144),
    rgba(241, 184, 227, 0.4)
  ); */
  /* background: linear-gradient(
    to bottom,
    rgb(74, 96, 224),
    rgba(36, 84, 216, 0.4)
  ); */
}

hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: #f4623a;
}

#title {
  margin-top: 5%;
  text-align: start;
  /* padding-left: 15%; */
  color: rgb(24, 48, 89);
  font-size: 2.3vw;
  font-weight: 500;
  /* font-family: "Nothing You Could Do", cursive; */
  font-family: "Merienda", cursive;
}

#services {
  font-family: "Merienda", cursive;
}

#spanStyle {
  font-size: 1.6vw;
  font-family: "Playfair Display", serif;
  /* font-family: "Open Sans Condensed", sans-serif; */
  text-align: start;
  font-weight: 600;
  margin-bottom: 3%;
  color: rgb(24, 48, 89);
}

.page-section {
  padding: 8rem 0;
}

#restStyle {
  color: #475657;
  /* font-family: "Patrick Hand", cursive; */
  /* font-family: "Open Sans Condensed", sans-serif; */
  font-size: 1.3vw;
  text-align: start;
  margin-bottom: 5%;
  font-family: "Playfair Display", serif;
  /* padding-right: 20%; */
}

#restStyle4 {
  color: #475657;
  /* font-family: "Patrick Hand", cursive; */
  /* font-family: "Open Sans Condensed", sans-serif; */
  font-size: 1.3vw;
  text-align: start;
  /* margin-bottom: 5%; */
  font-family: "Playfair Display", serif;
  /* padding-right: 20%; */
}

#restStyle3 {
  color: #475657;
  /* font-family: "Patrick Hand", cursive; */
  /* font-family: "Open Sans Condensed", sans-serif; */
  font-size: 1.1vw;
  text-align: start;
  margin-bottom: 5%;
  font-family: "Playfair Display", serif;
  /* padding-right: 20%; */
}

#payTitle {
  /* font-family: "Open Sans Condensed", sans-serif; */
  font-family: "Merienda", cursive;
  color: rgb(24, 48, 89);
}

#paytitle2 {
  color: white;
  font-family: "Merienda", cursive;
}

#serviceHeader {
  color: rgb(24, 48, 89);
  /* font-family: "Open Sans Condensed", sans-serif; */
  font-family: "Playfair Display", serif;
  font-weight: 500;
  font-size: 28px;
}

#head3 {
  font-weight: 500;
  font-family: "Playfair Display", serif;
  text-align: start;
  color: rgb(24, 48, 89);
  font-size: 2.5vw;
}

#head2 {
  font-weight: 500;
  font-family: "Playfair Display", serif;
  text-align: start;
  /* margin-left: 5%; */
  font-size: 1.4vw;
}

#serviceList {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 600;
}

/* #blogHeader {
  font-family: "Playfair Display", serif;
} */

#restStyle2 {
  /* font-family: "Open Sans Condensed", sans-serif; */
  font-family: "Playfair Display", serif;
  font-size: 1.6vw;
  text-align: start;
  border-left: 4px solid #3a6ea5;
  /* background-color: #8bc2fd; */
  background-color: #bcd7f3;
  padding: 2% 5%;
  margin-top: 5%;
  color: black;
  font-weight: 500;
}

#colStyle {
  background-color: #bcd7f3;
  color: #183059;
  padding: 2% 3%;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Playfair Display", serif;
}

#colStyle1 {
  /* border-left: 4px solid rgb(224, 74, 144); */
  background-color: #bcd7f3;
  color: #183059;
  padding: 2% 5%;
  margin-left: 3%;
  /* font-family: "Open Sans Condensed", sans-serif; */
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Playfair Display", serif;
}

#aboutTitle {
  text-align: start;
  margin-left: 8%;
  font-size: 6vw;
  font-family: "Cormorant Garamond", serif;
  color: #4d5061;
}

#detailedDiv {
  /* color: #e8e9eb; */
  /* font-family: "Patrick Hand", cursive; */
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 600;
  text-align: start;
  font-size: 2.5vw;
}

#titleDivider {
  border-color: #bcd7f3;
  border-width: 0.2rem;
}

#landingDisplay {
  /* color: rgba(255, 255, 255, 0.75); */
  /* font-family: "Nothing You Could Do", cursive; */
  font-family: "Open Sans Condensed", sans-serif;

  font-size: 1.5rem;
  font-weight: 600;
}

#priceTitle {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 600;
}

#priceDesc {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

#blogHeader {
  position: absolute;
  margin-top: 9%;
  font-size: 4vw;
  left: 8%;
  color: white;
  font-weight: 700;
  border-bottom: 4px solid white;
}

#bg-div {
  padding-bottom: 30px;
  border: 20px solid #b8a07e;
}

#mastText {
  color: white;
  font-size: 3vw;
  font-family: "Merienda", cursive;
}

#mastText2 {
  /* margin-top: 15%; */
  color: white;
  font-size: 2.4vw;
  /* background-image: url("robert-murray-j3a4HP3qKAE-unsplash.jpg"); */
  /* white-space: pre; */
  /* font-family: "EB Garamond", serif; */
  /* font-family: "Nothing You Could Do", cursive; */
  font-family: "Merienda", cursive;
  /* font-family: "Fredoka One", cursive; */
  /* font-family: "Permanent Marker", cursive; */
}

.div-img-bg {
  margin-top: 0%;
  padding-bottom: 30px;
  border: 20px solid #bcd7f3;
  width: 90%;
  margin-bottom: 5%;
  /* width: 90%; */
  /* height: "80%"; */
  /* background-color: #bcd7f3; */
}

#paddedRest {
  padding-left: 8%;
}

#changeImg2 {
  /* width: 100%; */
  /* box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14); */
  margin-top: -60px;
  margin-left: 40px;
  /* height: 60vh; */
  /* width: 90%; */
  -o-object-fit: cover;
  object-fit: cover;
}

#changeImg {
  /* width: 100%; */
  /* box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14); */
  margin-top: -60px;
  margin-left: -80px;
  /* height: 60vh; */
  /* width: 90%; */
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (min-width: 1100px) {
  #title {
    font-size: 1.8rem;
  }
  #spanStyle {
    font-size: 1.4rem;
    /* font-family: "Patrick Hand", cursive; */
    /* font-family: "Open Sans Condensed", sans-serif; */

    text-align: start;
    margin-bottom: 3%;
    /* padding-bottom: 5%; */
  }

  #detailedDiv {
    /* color: #e8e9eb; */
    /* font-family: "Patrick Hand", cursive; */
    text-align: start;
    font-size: 1.8rem;
    /* color: #6f8695; */
  }
}
/* 
@media only screen and (max-width: 700px) {
  #restStyle {
    font-size: 2vw;
  }
} */

@media only screen and (min-width: 1490px) {
  #head3 {
    /* font-size: 26px; */
  }
  .div-img-bg {
    margin-top: 25%;
    padding-bottom: 30px;
    border: 20px solid #bcd7f3;
  }
  #title {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1700px) {
  .div-img-bg {
    margin-top: 0%;
    padding-bottom: 30px;
    /* border: 20px solid #f4cae0; */
  }
  #landingDisplay {
    color: rgba(255, 255, 255, 0.75);
    font-family: "Cormorant Garamond", serif;
    font-size: 2.4rem;
    font-weight: 600;
  }
}

@media only screen and (min-width: 2000px) {
  #landingDisplay {
    color: rgba(255, 255, 255, 0.75);
    font-family: "Cormorant Garamond", serif;
    font-size: 2.8rem;
    font-weight: 600;
  }
  #title {
    font-size: 2.2rem;
  }
  #spanStyle {
    font-size: 2.2rem;
    font-family: "Patrick Hand", cursive;
    text-align: start;
    margin-bottom: 3%;
    /* padding-bottom: 5%; */
  }
  #detailedDiv {
    /* color: #e8e9eb; */
    font-family: "Patrick Hand", cursive;
    text-align: start;
    font-size: 2.3rem;
    /* color: #6f8695; */
  }
  #restStyle {
    font-family: "Lato", sans-serif;
    /* font-family: "Patrick Hand", cursive; */
    font-size: 1.5rem;
    text-align: start;
    margin-bottom: 5%;
    /* padding-right: 20%; */
  }
  #restStyle2 {
    font-family: "Patrick Hand", cursive;
    font-size: 1.8rem;
    text-align: start;
    border-left: 4px solid rgb(224, 74, 144);
    background-color: rgb(250, 218, 231);
    padding: 2% 5%;
    margin-top: 5%;
    color: white;
    /* border-radius: 18px; */
    /* padding-right: 20%; */
  }
}

@media only screen and (max-width: 1300px) {
  #col-top {
    margin-top: 5%;
  }
  .div-img-bg {
    margin-top: 25%;
    padding-bottom: 30px;
    /* border: 20px solid #f4cae0; */
  }
  #blogHeader {
    position: absolute;
    margin-top: 18%;
    font-size: 4vw;
    left: 8%;
    color: white;
    font-weight: 700;
    border-bottom: 4px solid white;
  }
}

@media only screen and (max-width: 991px) {
  #aboutTitle {
    display: none;
  }
  #paddedRest {
    padding-left: 0%;
  }
  .div-img-bg {
    margin-top: 13%;
    padding-bottom: 25px;
    /* border: 20px solid #f4cae0; */
  }
  #marginImg {
    margin: 0% auto;
  }
}

@media only screen and (max-width: 1000px) {
  #title {
    font-size: 2.6vw;
  }
  #spanStyle {
    font-size: 2.4vw;
  }
  #restStyle {
    font-size: 2vw;
  }
  #restStyle3 {
    font-size: 2vw;
  }
  #restStyle4 {
    font-size: 2vw;
  }
}

@media only screen and (max-width: 800px) {
  #restStyle {
    font-size: 2.3vw;
  }
  #spanStyle {
    font-size: 2.5vw;
  }
  #title {
    margin-top: 5%;
    text-align: start;
    /* padding-left: 15%; */
    color: rgb(24, 48, 89);
    font-size: 3vw;
    font-weight: 500;
    /* font-family: "Nothing You Could Do", cursive; */
    font-family: "Merienda", cursive;
  }

  #head2 {
    font-weight: 500;
    font-family: "Playfair Display", serif;
    text-align: start;
    /* margin-left: 5%; */
    font-size: 3vw;
  }
  #head3 {
    font-weight: 500;
    font-family: "Playfair Display", serif;
    text-align: start;
    color: rgb(24, 48, 89);
    font-size: 6vw;
  }
  #restStyle3 {
    color: #475657;
    /* font-family: "Patrick Hand", cursive; */
    /* font-family: "Open Sans Condensed", sans-serif; */
    font-size: 2.3vw;
    text-align: start;
    margin-bottom: 5%;
    font-family: "Playfair Display", serif;
    /* padding-right: 20%; */
  }
  #serviceHeader {
    color: rgb(24, 48, 89);
    /* font-family: "Open Sans Condensed", sans-serif; */
    font-family: "Playfair Display", serif;
    font-weight: 500;
    font-size: 24px;
  }

  #restStyle4 {
    color: #475657;
    /* font-family: "Patrick Hand", cursive; */
    /* font-family: "Open Sans Condensed", sans-serif; */
    font-size: 2.3vw;
    text-align: start;
    /* margin-bottom: 5%; */
    font-family: "Playfair Display", serif;
    /* padding-right: 20%; */
  }
  .div-img-bg {
    margin-top: 13%;
    padding-bottom: 25px;
    /* border: 20px solid #f4cae0; */
  }

  #changeImg {
    /* width: 100%; */
    /* box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14); */
    margin-top: -40px;
    margin-left: -40px;
    /* height: 300px; */
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media only screen and (max-width: 400px) {
  #head2 {
    font-weight: 500;
    font-family: "Playfair Display", serif;
    text-align: start;
    /* margin-left: 5%; */
    font-size: 6.5vw;
  }
  #head3 {
    font-weight: 500;
    font-family: "Playfair Display", serif;
    text-align: start;
    color: rgb(24, 48, 89);
    font-size: 9vw;
  }
  #colStyle1 {
    /* border-left: 4px solid rgb(224, 74, 144); */
    background-color: rgb(250, 218, 231);
    padding: 2% 5%;
    margin-top: 3%;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 600px) {
  #title {
    font-size: 6vw;
    margin-top: 15%;
  }
  #restStyle4 {
    color: #475657;
    /* font-family: "Patrick Hand", cursive; */
    /* font-family: "Open Sans Condensed", sans-serif; */
    font-size: 4.3vw;
    text-align: start;
    /* margin-bottom: 5%; */
    font-family: "Playfair Display", serif;
    /* padding-right: 20%; */
  }
  #restStyle3 {
    color: #475657;
    /* font-family: "Patrick Hand", cursive; */
    /* font-family: "Open Sans Condensed", sans-serif; */
    font-size: 4vw;
    text-align: start;
    margin-bottom: 5%;
    font-family: "Playfair Display", serif;
    /* padding-right: 20%; */
  }
  #mastText {
    font-size: 25px;
    margin-top: 5%;
  }
  #mastText2 {
    font-size: 20px;
  }

  #paddedRest {
    padding: 2%;
  }

  #landingDisplay {
    color: rgba(255, 255, 255, 0.75);
    font-family: "Cormorant Garamond", serif;
    font-size: 4.5vw;
    font-weight: 600;
  }

  #restStyle2 {
    margin-bottom: 5%;
    font-family: "Patrick Hand", cursive;
    font-size: 6vw;
    text-align: start;
    border-left: 4px solid rgb(224, 74, 144);
    background-color: rgb(250, 218, 231);
    padding: 2% 5%;
    margin-top: 5%;
    color: #6f8695;
    /* border-radius: 18px; */
    /* padding-right: 20%; */
  }
  #detailedDiv {
    /* color: #e8e9eb; */
    font-family: "Patrick Hand", cursive;
    text-align: start;
    font-size: 6.3vw;
    /* color: #6f8695; */
  }

  #colStyle1 {
    background-color: #bcd7f3;
    padding: 2% 5%;
    margin-top: 3%;
    margin-left: 0%;
  }
  #spanStyle {
    font-size: 6vw;
    /* font-family: "Patrick Hand", cursive; */
    font-family: "Playfair Display", serif;
    text-align: start;
    margin-bottom: 3%;
    /* padding-bottom: 5%; */
  }
  #restStyle {
    /* font-family: "Patrick Hand", cursive; */
    font-family: "Playfair Display", serif;
    font-size: 4.3vw;
    text-align: start;
    margin-bottom: 5%;
    /* padding-right: 20%; */
  }
}

#bg {
  /* height: 600px; */
  /* height: 300px; */
  background: linear-gradient(
      to bottom,
      rgb(245, 186, 217),
      rgba(241, 184, 227, 0.4)
    ),
    url("pexels-photo-196667.jpeg");
  /* background-image: url("plant.jpeg"); */
  height: 100%;
  opacity: 0.8;
  width: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.p-heading {
  font-size: 28px;
  text-align: left;
}

#blogHeader1 {
  /* margin-left: 7%; */
  /* margin-right: 5%; */
  margin-top: 5%;
  padding-left: 10%;
  text-align: start;
  border-left: 5px solid #000;
  /* font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  font-size: 3vw;
  font-weight: 700;
  white-space: pre;
}
